import request from "@/utils/request";
//访客管理

/**********************        认证用户           ***********************/

// 查询平台认证用户列表
export const getPlatformCustomerAuthPage = data =>
  request({
    url: `/api/v1/platform/web/customer/auth/getPlatformCustomerAuthPage`,
    data
  });

// 项目下的认证用户
export const customerAuthEstateList = estateId =>
  request({
    url: `/api/v1/platform/web/customer/auth/${estateId}/list`,
    method: "post"
  });

// 申请认证（入驻）审核
export const auditUserAuth = params =>
  request({
    url: `/api/v1/platform/web/customer/auth/audit/${params.id}`,
    params
  });

// 删除认证用户
export const deleteCustomerAuth = data =>
  request({
    url: `/api/v1/platform/web/customer/auth/deleteCustomerAuth`,
    data
  });

// 创建认证用户
export const insertCustomerAuth = data =>
  request({
    url: `/api/v1/platform/web/customer/auth/insertCustomerAuth`,
    data
  });

// 编辑认证用户
export const editCustomerAuth = data =>
  request({
    url: `/api/v1/platform/web/customer/auth/editCustomerAuth`,
    data
  });

// 推荐用户
export const editAuthTop = data =>
  request({
    url: `/api/v1/platform/web/customer/auth/editAuthTop`,
    data
  });

// 根据楼盘名称查询楼盘
export const getEstateByName = data =>
  request({
    url: `/api/v1/platform/web/customer/auth/getEstateByName`,
    data
  });

//隐藏显示认证用户
export const customerShow = data =>
  request({
    url: `/api/v1/platform/web/customer/auth/show`,
    data
  });

/**********************        访客概要           ***********************/

// 查询访客统计数据
export const getPlatformVisitorStatistics = data =>
  request({
    url: `/api/v1/platform/visitor/getPlatformVisitorStatistics`,
    data
  });

// 分页查询访客列表
export const getPlaformVisitorPage = data =>
  request({
    url: `/api/v1/platform/visitor/getPlaformVisitorPage`,
    data
  });

// 获取认证用户下的楼盘
export const authCustomerEstateList = id =>
  request({
    url: `/api/v1/platform/web/customer/auth/${id}/estateList`,
    method: "get"
  });

// 查询认证类型
export const getAuthTypeList = () =>
  request({
    url: `/api/v1/platform/manage/authtype/list`,
    method: "get"
  });

// 添加用户权限
export const addUserPermission = id =>
  request({
    url: `/api/v1/platform/web/customer/auth/${id}/permission`
  });

// 移除用户权限
export const removeUserPermission = id =>
  request({
    url: `/api/v1/platform/web/customer/auth/${id}/permission/remove`
  });
