import request from "@/utils/request";

// 机构列表
export function getMechanism(data) {
  return request({
    url: `/api/v1/platform/distribution/web/mechanism/list`,
    method: "post",
    data
  });
}

// 创建机构
export function createMechanism(data) {
  return request({
    url: `/api/v1/platform/distribution/web/mechanism/create`,
    method: "post",
    data
  });
}

// 编辑机构
export function editMechanism(data) {
  return request({
    url: `/api/v1/platform/distribution/web/mechanism/edit`,
    method: "post",
    data
  });
}

// 启用/停用机构
export function editMechanismStatus(data) {
  return request({
    url: `/api/v1/platform/distribution/web/mechanism/status`,
    method: "post",
    data
  });
}

// 删除机构
export function deleteMechanism(data) {
  return request({
    url: `/api/v1/platform/distribution/web/mechanism/delete/${data.id}`,
    method: "post",
    data
  });
}

// 渠道用户列表
export function getChannelUserList(data) {
  return request({
    url: `/api/v1/platform/distribution/web/channel/user/list`,
    method: "get",
    data
  });
}

// 新增渠道用户
export function addChannelUser(data) {
  return request({
    url: `/api/v1/platform/distribution/web/channel/user/add`,
    method: "post",
    data
  });
}

// 编辑渠道用户
export function editChannelUser(data) {
  return request({
    url: `/api/v1/platform/distribution/web/channel/user/edit`,
    method: "post",
    data
  });
}

// 删除渠道用户
export function deleteChannelUser(params) {
  return request({
    url: `/api/v1/platform/distribution/web/channel/user/del`,
    method: "post",
    params
  });
}

// 注册用户列表
export function customerList(data) {
  return request({
    url: `/api/v1/platform/distribution/web/customer/list`,
    method: "get",
    data
  });
}

// 设置管理员
export function setAdmin(params) {
  return request({
    url: `/api/v1/platform/distribution/web/admin/${params.customerId}`,
    method: "post",
    params
  });
}

// 审核
export function auditCustomer(params) {
  return request({
    url: `/api/v1/platform/distribution/web/audit/${params.customerId}`,
    method: "post",
    params
  });
}

// 删除
export function deleteCustomer(params) {
  return request({
    url: `/api/v1/platform/distribution/web/delete/${params.customerId}`,
    method: "post",
    params
  });
}

// 锁定
export function lockCustomer(params) {
  return request({
    url: `/api/v1/platform/distribution/web/lock/${params.customerId}`,
    method: "post",
    params
  });
}

// 机构下拉列表
export function mechanismListAll(data) {
  return request({
    url: `/api/v1/platform/distribution/web/mechanism/list/all`,
    method: "get",
    data
  });
}

// 导出注册员工
export function exportUserExcel(data) {
  return request({
    url: `/api/v1/platform/distribution/web/customer/export`,
    method: "get",
    data,
    originalData: true
  });
}

// 用户邀请关系树
export function getInvitationRelationalTree(data) {
  return request({
    url: `api/v1/platform/distribution/web/invitation/chain`,
    method: "get",
    data
  });
}
