<template>
  <div class="base-container">
    <!--header-mol-->
    <div class="header-mol">
      <div class="info">
        <div class="title">访问明细</div>
        <div class="summary">截止到{{ deadline }}的最新数据</div>
      </div>
    </div>
    <!--header-mol end-->
    <div class="filter mt20">
      <el-input
        placeholder="访客姓名、手机号"
        v-model="query.keyword"
        style="width: 320px;"
        class="mr10 f-search-bar"
      >
        <el-button slot="append" @click="search">搜索</el-button>
      </el-input>
      <el-select
        v-if="$route.path === '/distribution/userManage/outline'"
        v-model="query.distributionType"
        placeholder="用户类型"
        class="mr10"
        clearable
        @change="search"
      >
        <el-option
          v-for="(item, index) in distributionTypes"
          :key="index"
          :label="item.label"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <el-select
        v-if="$route.path === '/distribution/userManage/outline'"
        v-model="query.mechanismId"
        :placeholder="orgLabel"
        class="ml8"
        clearable
        @change="search"
      >
        <el-option
          :label="item.name"
          :value="item.id"
          v-for="item in mechanismList"
          :key="item.id"
        >
        </el-option>
      </el-select>
    </div>
    <el-table border class="distance mt20" :data="list">
      <el-table-column label="访客用户">
        <div slot-scope="scope" class="x aic">
          <el-image :src="scope.row.avatarPath" class="avatar">
            <img :src="$defaultAvatarUrl" slot="error" />
          </el-image>
          <p>{{ scope.row.customerName }}</p>
        </div>
      </el-table-column>
      <el-table-column label="联系电话">
        <template slot-scope="scope">
          <p v-if="scope.row.cellphone">
            {{ scope.row.cellphone | formatPhone }}
          </p>
          <p v-else>无</p>
        </template>
      </el-table-column>
      <el-table-column :label="orgLabel">
        <template v-slot="{ row }">
          <p>{{ row.mechanismName || "-" }}</p>
        </template>
      </el-table-column>
      <el-table-column label="用户类型">
        <template v-slot="{ row }">
          <p>{{ row.distributionTypeName || "-" }}</p>
        </template>
      </el-table-column>
      <el-table-column label="设备品牌">
        <template slot-scope="scope">
          <p>{{ scope.row.deviceBrand }}</p>
        </template>
      </el-table-column>
      <el-table-column label="设备型号" width="250px">
        <template slot-scope="scope">
          <p>{{ scope.row.deviceModel }}</p>
        </template>
      </el-table-column>
      <el-table-column label="最近访问">
        <template slot-scope="scope">
          <p>{{ scope.row.lastVisitTime }}</p>
        </template>
      </el-table-column>
    </el-table>
    <f-pagination
      :page-num.sync="query.pageNum"
      :page-size.sync="query.pageSize"
      :total="total"
      @change="getList"
    ></f-pagination>
  </div>
</template>

<script>
import {
  getPlatformVisitorStatistics,
  getPlaformVisitorPage
} from "@/api/visitorManage";
import { mechanismListAll } from "@/api/userManage";
export default {
  data() {
    return {
      statistics: {},
      title: "关联楼盘",
      query: {
        keyword: "",
        pageNum: 1,
        pageSize: 10,
        distributionType: null,
        id: null,
        mechanismId: null
      },
      total: 0,
      list: [],
      pageSizes: [10, 20, 30, 50],
      deadline: "",
      distributionTypes: [
        {
          id: 1,
          label: "渠道经纪人"
        },
        {
          id: 2,
          label: "新房置业顾问"
        },
        {
          id: 3,
          label: "平台员工"
        }
      ],
      isDistrPlatform: false, //是分销平台
      mechanismList: [] // 机构列表
    };
  },
  computed: {
    orgLabel() {
      return this.isDistrPlatform ? "自有渠道" : "所属机构";
    }
  },
  created() {
    this.query.id = this.$platformInfo.id;
    if (this.$route.path.includes("/distribution")) {
      this.isDistrPlatform = true;
    }
    this.getMechanismList();
    this.getList();
    this.getPlatformVisitorStatistics();
  },
  methods: {
    // 机构列表
    async getMechanismList() {
      const res = await mechanismListAll();
      if (res) {
        this.mechanismList = res;
      }
    },
    async getList() {
      //获取列表数据
      const res = await getPlaformVisitorPage(this.query);
      const { page, deadline } = res;
      const { list, total } = page;
      this.total = total;
      this.deadline = deadline;
      this.list = list || [];
    },
    search() {
      this.query.pageNum = 1;
      this.getList();
    },
    async getPlatformVisitorStatistics() {
      let query = {
        id: this.$platformInfo.id
      };
      const data = await getPlatformVisitorStatistics(query);
      this.statistics = data;
    }
  }
};
</script>
<style scoped lang="scss">
.distance {
  .avatar {
    height: 30px;
    width: 30px;
    border-radius: 100%;
    overflow: hidden;
    margin-right: 10px;
  }
}
</style>
