var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "base-container"
  }, [_c('div', {
    staticClass: "header-mol"
  }, [_c('div', {
    staticClass: "info"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("访问明细")]), _c('div', {
    staticClass: "summary"
  }, [_vm._v("截止到" + _vm._s(_vm.deadline) + "的最新数据")])])]), _c('div', {
    staticClass: "filter mt20"
  }, [_c('el-input', {
    staticClass: "mr10 f-search-bar",
    staticStyle: {
      "width": "320px"
    },
    attrs: {
      "placeholder": "访客姓名、手机号"
    },
    model: {
      value: _vm.query.keyword,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "keyword", $$v);
      },
      expression: "query.keyword"
    }
  }, [_c('el-button', {
    attrs: {
      "slot": "append"
    },
    on: {
      "click": _vm.search
    },
    slot: "append"
  }, [_vm._v("搜索")])], 1), _vm.$route.path === '/distribution/userManage/outline' ? _c('el-select', {
    staticClass: "mr10",
    attrs: {
      "placeholder": "用户类型",
      "clearable": ""
    },
    on: {
      "change": _vm.search
    },
    model: {
      value: _vm.query.distributionType,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "distributionType", $$v);
      },
      expression: "query.distributionType"
    }
  }, _vm._l(_vm.distributionTypes, function (item, index) {
    return _c('el-option', {
      key: index,
      attrs: {
        "label": item.label,
        "value": item.id
      }
    });
  }), 1) : _vm._e(), _vm.$route.path === '/distribution/userManage/outline' ? _c('el-select', {
    staticClass: "ml8",
    attrs: {
      "placeholder": _vm.orgLabel,
      "clearable": ""
    },
    on: {
      "change": _vm.search
    },
    model: {
      value: _vm.query.mechanismId,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "mechanismId", $$v);
      },
      expression: "query.mechanismId"
    }
  }, _vm._l(_vm.mechanismList, function (item) {
    return _c('el-option', {
      key: item.id,
      attrs: {
        "label": item.name,
        "value": item.id
      }
    });
  }), 1) : _vm._e()], 1), _c('el-table', {
    staticClass: "distance mt20",
    attrs: {
      "border": "",
      "data": _vm.list
    }
  }, [_c('el-table-column', {
    attrs: {
      "label": "访客用户"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return _c('div', {
          staticClass: "x aic"
        }, [_c('el-image', {
          staticClass: "avatar",
          attrs: {
            "src": scope.row.avatarPath
          }
        }, [_c('img', {
          attrs: {
            "slot": "error",
            "src": _vm.$defaultAvatarUrl
          },
          slot: "error"
        })]), _c('p', [_vm._v(_vm._s(scope.row.customerName))])], 1);
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "联系电话"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.cellphone ? _c('p', [_vm._v(" " + _vm._s(_vm._f("formatPhone")(scope.row.cellphone)) + " ")]) : _c('p', [_vm._v("无")])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": _vm.orgLabel
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_c('p', [_vm._v(_vm._s(row.mechanismName || "-"))])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "用户类型"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_c('p', [_vm._v(_vm._s(row.distributionTypeName || "-"))])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "设备品牌"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('p', [_vm._v(_vm._s(scope.row.deviceBrand))])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "设备型号",
      "width": "250px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('p', [_vm._v(_vm._s(scope.row.deviceModel))])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "最近访问"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('p', [_vm._v(_vm._s(scope.row.lastVisitTime))])];
      }
    }])
  })], 1), _c('f-pagination', {
    attrs: {
      "page-num": _vm.query.pageNum,
      "page-size": _vm.query.pageSize,
      "total": _vm.total
    },
    on: {
      "update:pageNum": function updatePageNum($event) {
        return _vm.$set(_vm.query, "pageNum", $event);
      },
      "update:page-num": function updatePageNum($event) {
        return _vm.$set(_vm.query, "pageNum", $event);
      },
      "update:pageSize": function updatePageSize($event) {
        return _vm.$set(_vm.query, "pageSize", $event);
      },
      "update:page-size": function updatePageSize($event) {
        return _vm.$set(_vm.query, "pageSize", $event);
      },
      "change": _vm.getList
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }